<template>
  <div class="notification_wrapper" v-show="loginStatus && showNotification">
    <div class="notice_mobile">
      <span class="notification" @click.stop.prevent="handleClickNotification">
        <img src="@/assets/uploads/dialog_info.png" alt="" />
      </span>
    </div>
    <div class="noticeDisable">
      <span> <img @click.stop.prevent="disableNotification" src="@/assets/uploads/dialog_false.png" alt="" /></span>
    </div>
    <div class="notice" @click.stop.prevent="handleClickNotification">
      <span
        class="notification"
        v-if="typeDisplay === 'Maintenance Notification'"
        v-html="
          enabledNoticeTitle +
          ': ' +
          this.$options.filters.dateFormatWithTimeZone(startTime) +
          ' - ' +
          this.$options.filters.dateFormatWithTimeZone(endTime)
        "
        ><i class="el-icon-warning"></i>
      </span>
      <span v-html="enabledNoticeTitle" class="notification" v-if="typeDisplay === 'Notification'"
        ><i class="el-icon-warning"></i>
      </span>
    </div>
    <template slot="components">
      <Notice
        :notificationVisible.sync="notificationVisible"
        :enabledNotice="enabledNotice"
        :enabledNoticeTitle="enabledNoticeTitle"
      ></Notice>
    </template>
  </div>
</template>

<script>
import { apiGetTranslation, apiGetNotifications } from '@/resource';
import Notice from '@/components/notification/Notice';

export default {
  props: { lang: String },
  components: {
    Notice
  },
  watch: {
    loginStatus(loginStatus) {
      if (loginStatus) {
        this.getNotification(false);
      }
    },
    lang(lang) {
      this.getNotification();
    }
  },
  data() {
    return {
      notificationVisible: false,
      showNotification: true,
      enabledNotice: null,
      enabledNoticeTitle: null,
      startTime: null,
      endTime: null,
      typeDisplay: null
    };
  },
  mounted() {
    if (this.loginStatus) {
      this.getNotification(this.autoNotifyStatus);
    }
  },
  methods: {
    disableNotification() {
      this.showNotification = false;
    },
    handleClickNotification() {
      this.notificationVisible = true;
    },
    getTranslated() {
      // call google translate api
      apiGetTranslation({
        q: this.enabledNoticeTitle,
        source: 'en',
        target: this.$options.filters.googleLangSwitch(this.lang)
      })
        .then(resp => {
          this.enabledNoticeTitle = resp.data.data.translations[0].translatedText;
        })
        .catch(err => {
          console.log(err);
        });
      apiGetTranslation({
        q: this.enabledNotice,
        source: 'en',
        target: this.$options.filters.googleLangSwitch(this.lang)
      })
        .then(resp => {
          this.enabledNotice = resp.data.data.translations[0].translatedText;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getNotification(isShow) {
      apiGetNotifications({ country: this.countryCode })
        .then(res => {
          if (res.data.data.length === 0) {
            this.disableNotification();
          } else {
            let document = res.data.data[0].document
              .replace(/amp;/gi, '')
              .replace(/&lt;/gi, '<')
              .replace(/&gt;/gi, '>')
              .replace(/&quot;/gi, '"')
              .replace(/&nbsp;/gi, ' ');
            this.enabledNotice = document;
            if (res.data.data[0].type_display === 'Maintenance Notification') {
              this.typeDisplay = 'Maintenance Notification';
              this.startTime = document
                .match(/\[(.*?)\]/)[1]
                .split('to')[0]
                .trim();
              this.endTime = document
                .match(/\[(.*?)\]/)[1]
                .split('to')[1]
                .trim();
              this.enabledNoticeTitle = res.data.data[0].subject;
              let delimiter = document.match(/\[(.*?)\]/)[0];
              this.enabledNotice =
                document.split(delimiter)[0] +
                this.$options.filters.dateFormatWithTimeZone(this.startTime) +
                '-' +
                this.$options.filters.dateFormatWithTimeZone(this.endTime) +
                document.split(delimiter)[1];
            } else if (res.data.data[0].type_display === 'Notification') {
              this.typeDisplay = 'Notification';
              this.startTime = null;
              this.endTime = null;
              this.enabledNoticeTitle = res.data.data[0].subject;
              const matchGroup = document.match(/\d{4}-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2} EDT/g);
              if (matchGroup !== null) {
                this.enabledNotice = document
                  .replace(matchGroup[0], this.$options.filters.dateFormatWithTimeZone(matchGroup[0]))
                  .replace(matchGroup[1], this.$options.filters.dateFormatWithTimeZone(matchGroup[1]));
              } else {
                this.enabledNotice = document;
              }
            }
            if (this.lang !== 'en_US') this.getTranslated();
            if (res.data.data[0].announcementDisplayType === 0 && isShow) {
              this.$store.commit('common/setAutoNotifyStatus', false);
              this.notificationVisible = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          this.showNotification = false;
        });
    }
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    },
    loginStatus() {
      return this.$store.state.common.loginStatus;
    },
    autoNotifyStatus() {
      return this.$store.state.common.autoNotifyStatus;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/notification/headerNotification.scss';
</style>
