var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loginStatus && _vm.showNotification),expression:"loginStatus && showNotification"}],staticClass:"notification_wrapper"},[_c('div',{staticClass:"notice_mobile"},[_c('span',{staticClass:"notification",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleClickNotification.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/uploads/dialog_info.png"),"alt":""}})])]),_c('div',{staticClass:"noticeDisable"},[_c('span',[_c('img',{attrs:{"src":require("@/assets/uploads/dialog_false.png"),"alt":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.disableNotification.apply(null, arguments)}}})])]),_c('div',{staticClass:"notice",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleClickNotification.apply(null, arguments)}}},[(_vm.typeDisplay === 'Maintenance Notification')?_c('span',{staticClass:"notification",domProps:{"innerHTML":_vm._s(
        _vm.enabledNoticeTitle +
        ': ' +
        this.$options.filters.dateFormatWithTimeZone(_vm.startTime) +
        ' - ' +
        this.$options.filters.dateFormatWithTimeZone(_vm.endTime)
      )}},[_c('i',{staticClass:"el-icon-warning"})]):_vm._e(),(_vm.typeDisplay === 'Notification')?_c('span',{staticClass:"notification",domProps:{"innerHTML":_vm._s(_vm.enabledNoticeTitle)}},[_c('i',{staticClass:"el-icon-warning"})]):_vm._e()]),_c('template',{slot:"components"},[_c('Notice',{attrs:{"notificationVisible":_vm.notificationVisible,"enabledNotice":_vm.enabledNotice,"enabledNoticeTitle":_vm.enabledNoticeTitle},on:{"update:notificationVisible":function($event){_vm.notificationVisible=$event},"update:notification-visible":function($event){_vm.notificationVisible=$event}}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }