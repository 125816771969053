<template>
  <header>
    <ul class="clearfix">
      <li class="fl">
        <img src="@/assets/uploads/header/scroll.png" class="header_btn" id="menu_open_button" @click="showMenu" />
        <!-- 通知 -->
        <HeaderNotification :lang="lang"></HeaderNotification>
      </li>

      <li class="fr">
        <el-dropdown trigger="click" @command="langCommand">
          <div class="area">
            <img class="areaImg" src="@/assets/uploads/header/language.png" alt />
            <span class="areaName">{{ language.label }}</span>
          </div>

          <el-dropdown-menu id="lang" slot="dropdown" class="lang_dropdown_box" placement="right-end">
            <el-dropdown-item
              v-for="item in languages"
              :key="item.value"
              :command="item"
              :class="{ active: lang == item.value }"
            >
              <img :src="item.icon" />

              <span>{{ item.label }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown class="user" trigger="click" @command="handleCommand" @visible-change="addStyle">
          <div class="login_inner">
            <img src="@/assets/uploads/header/profile.png" class="member_logo" />
          </div>

          <el-dropdown-menu slot="dropdown" class="login_dropdown_box">
            <el-dropdown-item command="backToClientPortal" class="login_back" v-if="!isSubUser">
              <div class="icon"></div>

              <span>{{ $t('header.bckToCP') }}</span>
            </el-dropdown-item>

            <el-dropdown-item command="logout" class="logout">
              <div class="icon"></div>

              <span>{{ $t('header.logout') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>
  </header>
</template>

<script>
import helper from '@/util/signinHelper';
import HeaderNotification from '@/components/notification/HeaderNotification';

export default {
  name: 'vHeader',
  props: { matches: Boolean },
  components: { HeaderNotification },
  data() {
    return {
      language: '',
      languages: [
        {
          value: 'en_US',
          label: 'English',
          icon: require('@/assets/uploads/flag/uk_flag.png')
        },
        {
          value: 'fr_FR',
          label: 'Français',
          icon: require('@/assets/uploads/flag/fr_flag.png')
        },
        {
          value: 'es',
          label: 'Español',
          icon: require('@/assets/uploads/flag/es_flag.png')
        },
        {
          value: 'pt',
          label: 'Português',
          icon: require('@/assets/uploads/flag/pt_flag.png')
        },
        {
          value: 'de',
          label: 'Deutsch',
          icon: require('@/assets/uploads/flag/de_flag.png')
        }
      ],
      openImg: false
    };
  },
  mounted() {
    let callback = (val, oldVal, uri) => {
      console.log('localStorage change', val);
      this.$nextTick(() => {
        if (val != this.userName) location.reload();
      });
    };

    this.$ls.on('setUserName', callback); //watch change foo key and triggered callback
  },
  watch: {
    language(lang) {
      this.lang = lang.value;
      document.body.dir = lang.value === 'ar' ? 'rtl' : 'ltr';
    },
    lang: {
      immediate: true,
      handler(val) {
        this.language = this.languages.find(f => f.value == val) || this.languages[0];
        this.setChatLanguage(val);
      }
    }
  },
  computed: {
    userName() {
      return this.$store.state.common.isSubUser ? this.$store.state.common.subEmail : this.$store.state.common.userName;
    },
    lang: {
      get() {
        return this.$store.state.common.lang;
      },
      set(value) {
        return this.$store.commit('common/setLang', value);
      }
    },
    isSubUser() {
      return this.$store.state.common.isSubUser;
    }
  },
  methods: {
    addStyle(bool) {
      this.openImg = bool;
    },
    showMenu() {
      this.$emit('openMenu');
    },
    handleCommand(command) {
      helper.signOut(command);
    },
    langCommand(command) {
      this.language = command;
    },
    setChatLanguage(val) {
      zE(function () {
        $zopim(function () {
          setTimeout(() => {
            $("div[data-test-id='ChatWidgetButton']").remove();
          }, 0);
          $zopim.livechat.setLanguage(val);
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/header.scss';
</style>
