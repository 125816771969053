<template>
  <div class="notification_dialog">
    <el-dialog title="notification" :visible.sync="visible" top="0" :append-to-body="true" :fullscreen="fullscreen">
      <div slot="title">
        <slot name="header"></slot>
        <img src="@/assets/uploads/close.png" class="closeImg" alt @click="close" />
      </div>
      <div class="dialog_body">
        <p class="title" v-html="enabledNoticeTitle"></p>
        <div v-html="enabledNotice"></div>
      </div>

      <!-- <div slot="footer">
        <a @click="showLiveChat" data-testid="showLiveChat">
          <img src="@/assets/uploads/footer_chat_icon.png" alt />
          <span class="help">Need Help? Live Chat</span>
        </a>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'notice',
  props: {
    notificationVisible: Boolean,
    enabledNotice: String,
    enabledNoticeTitle: String
  },
  data() {
    return {
      notificationContent: null,
      visible: false,
      fullscreen: false
    };
  },
  watch: {
    notificationVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:notificationVisible', bool);
    }
  },
  methods: {
    close() {
      this.$emit('update:notificationVisible', false);
    },
    getMedia() {
      const matches = window.matchMedia('(max-width: 768px)').matches;
      if (matches) this.fullscreen = true;
      else this.fullscreen = false;
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/notification/notice.scss';
</style>
