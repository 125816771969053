<template>
  <div id="menu">
    <div class="menu_shadow" v-show="matches && onOpen" @click="matchesClose"></div>

    <div class="menu_wrapper" :style="menu_wrapperStyle">
      <div class="menu_logo" v-if="(!isCollapse || matches) && onOpen">
        <router-link to="/home" class="logo">
          <img alt class="logoImg" />

          <p class="product">ib Portal</p>
        </router-link>
      </div>
      <div class="menu_logo_small" v-else>
        <router-link to="/home" class="logo">
          <img alt class="logoImg" />
        </router-link>
      </div>

      <el-scrollbar style="height: 100%">
        <el-collapse-transition>
          <el-menu
            class="el-menu-vertical"
            background-color="#2e3880"
            text-color="rgb(255, 255, 255)"
            active-text-color="#41ba92"
            :router="true"
            :unique-opened="true"
            :default-active="$route.path"
            :collapse="isCollapse && !matches"
            data-testid="menu"
          >
            <div v-for="(menus, i) in $config.menu" v-bind:key="i">
              <span class="nav-group__title" v-if="menus.name && !isCollapse">{{ $t(menus.name) }}</span>

              <div v-for="(menu, j) in menus.children" v-bind:key="j" class="divider">
                <el-menu-item :index="menu.path">
                  <img
                    :src="menu.icon"
                    class="icon"
                    :class="{ icon_small: (isCollapse || !matches) && !onOpen }"
                    alt=""
                  />

                  <span slot="title" :data-testid="`click_${menu.path}`">{{ $t(menu.name) }}</span>
                </el-menu-item>
              </div>
            </div>

            <a
              class="swith_to"
              @click.stop.prevent="redirectToCp"
              v-if="(!isCollapse || matches) && !isSubUser"
              data-testid="redirectToCp"
            >
              {{ $t('header.bckToCP') }}
            </a>
          </el-menu>
        </el-collapse-transition>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import helper from '@/util/signinHelper';

export default {
  name: 'vMenu',
  props: {
    isCollapse: Boolean,
    matches: Boolean,
    onOpen: Boolean
  },
  data() {
    return {
      bodyDirection: null
    };
  },
  methods: {
    matchesClose() {
      this.$emit('matchesClose');
    },
    redirectToCp() {
      helper.signOut();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.bodyDirection = document.body.dir;
    });

    $('.el-menu-item').click(() => {
      if (this.matches) this.matchesClose();
    });
  },
  computed: {
    menu_wrapperStyle() {
      const menuWidth = { width: this.matches == false && this.isCollapse ? '64px' : '230px' };
      const direction = this.bodyDirection === 'rtl' ? { right: this.menuPosition } : { left: this.menuPosition };
      return { ...direction, ...menuWidth };
    },
    menuPosition() {
      return this.matches && !this.onOpen ? '-230px' : '0px';
    },
    isSubUser() {
      return this.$store.state.common.isSubUser;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/menu.scss';
</style>
