<template>
  <el-container :key="reRender" class="wrapper">
    <el-aside :width="!matches ? center : ''">
      <slide-bar
        menu-click-outside="click"
        :isCollapse.sync="isCollapse"
        :onOpen.sync="onOpen"
        :matches="matches"
        @matchesClose="matchesClose"
      ></slide-bar>
    </el-aside>

    <el-container class="is-vertical">
      <v-header :matches="matches" data-v-step="2" @openMenu="openMenu"></v-header>

      <el-main>
        <router-view></router-view>

        <v-footer></v-footer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import vHeader from '@/components/Header';
import vFooter from '@/components/Footer';
import slideBar from '@/components/Menu';
import { apiSetlanguage } from '@/resource';

export default {
  name: 'layout',
  data() {
    return {
      reRender: 0,
      center: '230px',
      showFlag: false,
      matches: false,
      isCollapse: false,
      onOpen: true
    };
  },
  components: { vHeader, vFooter, slideBar },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    lang: {
      immediate: true,
      handler(val) {
        this.$i18n.locale = val;
        if (this.$store.state.common.loginStatus) this.setUserLanguagePreference(val);
        this.reRender++;
      }
    },
    onOpen(bool) {
      this.isCollapse = !bool;
    },
    isCollapse(bool) {
      if (!bool && this.onOpen) this.center = '230px';
      else if (!this.matches) this.center = '64px';
      else this.center = '0';
    },
    matches(bool) {
      if (bool) {
        this.onOpen = false;
        this.center = '0';
        this.isCollapse = false;
      } else {
        this.onOpen = true;
        this.center = '230px';
      }
    }
  },
  mounted() {
    /** 判斷尺寸 */
    const vm = this;
    vm.matches = window.matchMedia('(max-width: 1024px)').matches;
    $(window).resize(function () {
      vm.matches = window.matchMedia('(max-width: 1024px)').matches;
    });
  },
  methods: {
    async setUserLanguagePreference(lang) {
      await apiSetlanguage({ language: lang });
      this.$store.dispatch('regulator/actionGetInformation');
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
      this.onOpen = !this.onOpen;
    },
    matchesClose() {
      this.onOpen = !this.onOpen;
      this.isCollapse = !this.isCollapse;
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/css/app.scss';
@import '../assets/GTWalsheimPro/stylesheet.css';
</style>
